import {Navigate} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from "./pages/Systems/Logs/Logs";
import Dashboard from "./pages/Dashboard/Dashboard";
import EmailTemplateList from "./pages/Templates/Email-Templates/EmailTemplateList";
import EmailTemplateCreate from "./pages/Templates/Email-Templates/EmailTemplateCreate";
import EmailTemplateEdit from "./pages/Templates/Email-Templates/EmailTemplateEdit";
import CommonSettings from "./pages/Systems/CommonSettings/CommonSettings";
import UpdateSystem from "./pages/Systems/UpdateSystem/UpdateSystem";
import Notification from "./pages/Settings/Notification";
import UserAdd from "./pages/User/UserAdd";
import EmailHistoryList from "./pages/Templates/Email-Hstory/EmailHistoryList";
import FeedBackList from "./pages/FeedBacks/FeedBackList";
import FeedBackEdit from "./pages/FeedBacks/FeedBackEdit";
import Info from "./pages/Info/Info";
import ObjectList from "./pages/Objects/ObjectList";
import RequestsList from "./pages/Requests/RequestsList";
import PlaceForWorkList from "./pages/PlaceForWork/PlaceForWorkList";
import TypeOfWorkList from "./pages/TypeOfWork/TypeOfWorkList";
import ObjectAdd from "./pages/Objects/ObjectAdd";
import ObjectInfo from "./pages/Objects/ObjectInfo";
import ObjectEdit from "./pages/Objects/ObjectEdit";
import PlaceForWorkAdd from "./pages/PlaceForWork/PlaceForWorkAdd";
import PlaceForWorkEdit from "./pages/PlaceForWork/PlaceForWorkEdit";
import TypeOfWorkAdd from "./pages/TypeOfWork/TypeOfWorkAdd";
import TypeOfWorkEdit from "./pages/TypeOfWork/TypeOfWorkEdit";
import RequestsEdit from "./pages/Requests/RequestsEdit";
import RequestsAdd from "./pages/Requests/RequestsAdd";
import RequestInfo from "./pages/Requests/RequestInfo";
import DocumentEdit from './pages/Documents/DocumentEdit';
import DocumentAdd from './pages/Documents/DocumentAdd';
import DocumentsList from './pages/Documents/DocumentsList';


const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            {path: 'account', element: <Account/>},
            {path: 'dashboard', element: <Dashboard/>},

            {path: 'user/:id', element: <UserInfo/>},
            {path: 'user/edit/:id', element: <UserEdit/>},
            {path: 'user/add', element: <UserAdd/>},
            {path: 'users', element: <UserList/>},

            {path: 'objects', element: <ObjectList/>},
            {path: 'objects/:id', element: <ObjectInfo/>},
            {path: 'objects/add', element: <ObjectAdd/>},
            {path: 'objects/edit/:id', element: <ObjectEdit/>},

            {path: 'requests', element: <RequestsList/>},
            {path: 'requests/:id', element: <RequestInfo/>},
            {path: 'requests/edit/:id', element: <RequestsEdit/>},
            {path: 'requests/add', element: <RequestsAdd/>},

            {path: 'place-for-work', element: <PlaceForWorkList/>},
            {path: 'place-for-work/add', element: <PlaceForWorkAdd/>},
            {path: 'place-for-work/edit/:id', element: <PlaceForWorkEdit/>},

            {path: 'type-of-work', element: <TypeOfWorkList/>},
            {path: 'type-of-work/add', element: <TypeOfWorkAdd/>},
            {path: 'type-of-work/edit/:id', element: <TypeOfWorkEdit/>},

            {path: 'feedbacks/edit/:id', element: <FeedBackEdit/>},
            {path: 'feedbacks', element: <FeedBackList/>},

            { path: 'info', element: <Info/> },

            { path: 'email-templates', element: <EmailTemplateList /> },
            { path: 'email-history', element: <EmailHistoryList /> },
            { path: 'email-templates/create', element: <EmailTemplateCreate /> },
            { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

            { path: 'documents/edit/:id', element: <DocumentEdit /> },
            { path: 'documents/add', element: <DocumentAdd /> },
            { path: 'documents', element: <DocumentsList /> },

            {path: 'logs', element: <Logs/>},
            {path: 'common-settings', element: <CommonSettings/>},
            {path: 'update-system', element: <UpdateSystem/>},

            {path: 'settings', element: <Notification/>},

            {path: '', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <Login/>},
            {path: '404', element: <NotFound/>},
            {path: '/', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
