import React, {useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {useRoutes, useLocation, useNavigate} from 'react-router-dom';
import {ThemeProvider, StyledEngineProvider} from '@material-ui/core';
import GlobalStyles from './components/Styles/GlobalStyles';
import theme from './theme';
import routes from './routes';
import TokenStorage from './API/TokenStorage';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import {firebaseConfig} from './firebase'
import {usePut} from "./API/request";
import {useDispatch} from "react-redux";
import {setFirebaseToken} from "./redux/slices/auth";
const App = () => {

    const content = useRoutes(routes);
    const navigate = useNavigate();

    if (window.location.pathname.includes('app') && TokenStorage.getAccessToken() === '') {
        navigate('/login', {replace: true});
    }
    const putU = usePut();
    const dispatch = useDispatch();

    useEffect(() => {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                console.log("Notification permission granted.");
                const app = initializeApp(firebaseConfig);

                const messaging = getMessaging(app);

                getToken(messaging, {
                    vapidKey: "BNQEr-z7IEFSBnBoEFQbvYV6ksGGGsEtSrPZA9cEQBLl2YMC28TPN6-TBuACa2Ob1AxDMCPJ8CdnK_rwDEW4SV0",
                }).then((currentToken) => {
                    console.log(currentToken)
                    if (currentToken) {
                        dispatch(setFirebaseToken(currentToken))
                        TokenStorage.setFirebaseToken(currentToken)
                    } else {
                        console.log("Can not get token");
                    }
                });
            } else {
                console.log("Do not have permission!");
            }
        });
    },[]);


    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles/>
                    {content}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
