import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    TextField,
    CardContent,
    Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML
} from 'draft-js';

const DocumentAdd = () => {
    const navigate = useNavigate();
    const postU = usePost();

    const [values, setValues] = useState({
        key: ''
    });
    const [errors, setErrors] = useState({
        key: false
    });

    const [content, setContent] = useState('');


    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(content))
        )
    );

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.key === '') {
            validComplete = false;
            formErrors.key = false;
            showAlert('error', 'Поле Ключ не должно быть пустым');
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        setValues({
            key: ''
        });
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            const data = {
                key: values.key,
                content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            };

            postU('pages', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Документ добавлен');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                    setIsShowLoader(false);
                    setSubmitDisabled(false);
                })
                .finally(() => {
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Documents</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    Documents
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Documents</li>
                    <li>/</li>
                    <li>Edit</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader title="Adding a new document"/>
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                        fullWidth
                                        label="Key"
                                        margin="normal"
                                        name="key"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.key}
                                        variant="outlined"
                                        error={errors.key}
                                    />
                                    <Typography
                                        style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}
                                    >
                                        Content
                                    </Typography>

                                    <Editor
                                        editorStyle={{
                                            border: '1px solid rgba(0,0,0,0.2)',
                                            minHeight: 500,
                                            padding: 10
                                        }}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                    />
                                    <Alert
                                        severity={alert.type}
                                        style={{ display: alert.isVisible ? 'flex' : 'none' }}
                                    >
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DocumentAdd;
