import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useDelete, useGet, usePut} from '../../API/request';
import {BallTriangle} from "react-loader-spinner";
import {useConfirm} from "../../components/Confirm";
import Typography from "@material-ui/core/Typography";

const Info = () => {

    const getU = useGet();
    const putU = usePut();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [platform, setPlatform] = useState([]);
    const [project, setProject] = useState([]);
    const [tariff, setTariff] = useState([]);

    const [isLoaded, setIsLoaded] = useState(true);


    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };

    const loadThemes = () => {
        setIsLoaded(true)


        let endpoint = `project`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    console.log(resp)
                    setTariff(resp.data.info.tariff);
                    setPlatform(resp.data.info.platform)
                    setProject(resp.data.info.project)
                }
            })
            .catch((err) => {
                setTariff([]);
                showAlert('error', err.response.data.message.message);
            })
            .finally(() =>{
                setIsLoaded(false)
            });

    };

    useEffect(() => {
        loadThemes();
    }, []);



    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Info</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Информация
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>

                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 600}}>
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            sx={{
                                                margin: 2
                                            }}
                                        >
                                            Тариф
                                        </Typography>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Наименование
                                                    </TableCell>
                                                    <TableCell>
                                                        Значение
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(tariff)?.map(([key, value]) => (
                                                    <TableRow hover key={key}>
                                                        <TableCell>
                                                            {key || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {value || "---"}
                                                        </TableCell>
                                                    </TableRow>

                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 600}}>
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            sx={{
                                                margin: 2
                                            }}
                                        >
                                            Платформа
                                        </Typography>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Наименование
                                                    </TableCell>
                                                    <TableCell>
                                                        Значение
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(platform)?.map(([key, value]) => (
                                                    <TableRow hover key={key}>
                                                        <TableCell>
                                                            {key || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {value || "---"}
                                                        </TableCell>
                                                    </TableRow>

                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 600}}>
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            sx={{
                                                margin: 2
                                            }}
                                        >
                                            Проект
                                        </Typography>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Наименование
                                                    </TableCell>
                                                    <TableCell>
                                                        Значение
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(project)?.map(([key, value]) => (
                                                    <TableRow hover key={key}>
                                                        <TableCell>
                                                            {key || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {value || "---"}
                                                        </TableCell>
                                                    </TableRow>

                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>


                </Container>
            </Box>
        </>
    );
};

export default Info;
