import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet, usePut} from '../../API/request';
import {BallTriangle} from "react-loader-spinner";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import TokenStorage from "../../API/TokenStorage";
import {setUser} from "../../redux/slices/auth";

const Notification = () => {

    const getU = useGet();
    const putU = usePut();
    const deleteU = useDelete();
    const dispatch = useDispatch()

    const [isLoaded, setIsLoaded] = useState(true);
    const [isSubscribeDisabled, setIsSubscribeDisabled] = useState(false);
    const [isUnsubscribeDisabled, setIsUnsubscribeDisabled] = useState(false);

    const isHasSubscribe = useSelector(state => state.auth.user?.deviceToken)
    const firebaseTokenRedux = useSelector((state) => state.auth.firebaseToken)
    const firebaseTokenStorage = TokenStorage.getFirebaseToken();


    useEffect(() =>{
        setIsLoaded(true)
        if (isHasSubscribe){
            setIsSubscribeDisabled(true)
            setIsUnsubscribeDisabled(false)
            setIsLoaded(false)
        } else {
            setIsUnsubscribeDisabled(true)
            setIsSubscribeDisabled(false)
            setIsLoaded(false)
        }
    },[isHasSubscribe])


    const subscribed = () =>{
        putU('users/current/device', {deviceToken: firebaseTokenStorage || firebaseTokenRedux})
            .then((response) => {
                if (response.status === 'success') {
                    console.log('You have subscribed to notifications')
                    dispatch(setUser(response.data.user))
                    TokenStorage.setUser(response.data.user);
                }
            })
            .catch((e) => {
                console.log(e?.response?.data)
            })
            .finally(() => {

            })
    }
    const unsubscribed = () =>{
        deleteU('users/current/device')
            .then((response) =>{
                if (response.status === 'success'){
                    console.log('You have unsubscribed from notifications')
                    dispatch(setUser(response.data.user))
                    TokenStorage.setUser(response.data.user);
                }
            })
            .catch((e) =>{
                console.log(e?.response?.data)
            })
            .finally(() =>{

            })
    }

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Notification</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Notification
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 300}}>
                                        <Table>
                                            <TableBody>
                                                <TableRow hover>
                                                    <TableCell>
                                                        Subscribe to notifications
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end'
                                                        }}>
                                                            <Button
                                                                disabled={isSubscribeDisabled}
                                                                onClick={subscribed}
                                                                style={{
                                                                    marginRight: '10px'
                                                                }}
                                                                color="primary"
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                            >
                                                                Subscribe
                                                            </Button>
                                                            <Button
                                                                disabled={isUnsubscribeDisabled}
                                                                onClick={unsubscribed}
                                                                color="primary"
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                            >
                                                                Unsubscribed
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>

                </Container>
            </Box>
        </>
    );
};

export default Notification;
