import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    TextField,
    Alert,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell, TableBody, TableFooter, TablePagination
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate, Link} from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import {useGet, usePost, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import '../../styles/Avatar/style.css'
import Modal from "@mui/material/Modal";
import PerfectScrollbar from "react-perfect-scrollbar";
import ModalGetUser from "../../components/ModalGetUser/ModalGetUser";
import ModalGetObject from "../../components/ModalGetObject/ModalGetObject";
import moment from 'moment';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';


const RequestsAdd = () => {

    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();
    const postU = usePost();

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [modalStatus, setModalStatus] = useState({
        isOpen: false,
        field: null,
    });
    const [isOpenObjectModal, setIsOpenObjectModal] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);


    const [email, setEmail] = useState('');
    const [object, setObject] = useState('');
    const [typeOfWorkList, setTypeOfWorkList] = useState([]);
    const [placeForWorkList, setPlaceForWorkList] = useState([]);

    const [values, setValues] = useState({
        user: '',
        object: '',
        date: '2025-01-01',
        workType: '',
        comment: '',
        room: '',
    });
    const [errors, setErrors] = useState({
        user: false,
        object: false,
        date: false,
        workType: false,
        comment: false,
        room: false,
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const handleChangeObject = (object) => {
        setValues({
            ...values,
            object: object.id
        })
        setObject(object.address)
        setIsOpenObjectModal(false)
    }

    const handleChangeOwners = ({email, id}) => {
        if (modalStatus.field === 'owner') {
            setValues({
                ...values,
                user: id
            })
            setEmail(email)
            setModalStatus({
                isOpen: false,
                field: null,
            })
        } else {
            setModalStatus({
                isOpen: false,
                field: null,
            })
        }
    }

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.user === '') {
            validComplete = false;
            formErrors.user = false;
            showAlert('error', "The User field should not be empty")
        }

        if (values.object === '') {
            validComplete = false;
            formErrors.object = false;
            showAlert('error', "The Object field should not be empty")
        }

        if (values.workType === '') {
            validComplete = false;
            formErrors.workType = false;
            showAlert('error', "The Type of work field should not be empty")
        }

        if (values.comment === '') {
            validComplete = false;
            formErrors.comment = false;
            showAlert('error', "The Comment field should not be empty")
        }

        if (values.room === '') {
            validComplete = false;
            formErrors.room = false;
            showAlert('error', "The Place for work field should not be empty")
        }

        setErrors(formErrors);
        return validComplete;
    };


    const submitInfo = async () => {
        if (validateInfo()) {
            setSubmitDisabled(true);

            let data = {
                userId: values.user,
                objectId: values.object,
                date: values.date,
                comment: values.comment,
                workType: values.workType,
                room: values.room
            }

            postU(`requests/manager`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'You have successfully added a new request');
                        setValues({
                            user: '',
                            object: '',
                            date: '2025-01-01',
                            workType: '',
                            comment: '',
                            room: '',
                        })
                        setEmail('')
                        setObject('')
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    const getListOfData = () => {
        setIsLoaded(true)
        getU(`work/types?page=1&limit=100`)
            .then((response) => {
                if (response.status === 'success') {
                    setTypeOfWorkList(response.data.workTypes)
                }
            })
            .catch((err) => {
                showAlert('error', err.response.data.message);
            })
            .finally(() => {

            })
        getU(`rooms?page=1&limit=100`)
            .then((response) => {
                if (response.status === 'success') {
                    setPlaceForWorkList(response.data.rooms);
                }
            })
            .catch((err) => {
                showAlert('error', err.response.data.message);
            })
            .finally(() => {
                setIsLoaded(false)
            })
    }

    useEffect(() => {
        getListOfData()
    }, [])


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Add Requests</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    Requests
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Requests</li>
                    <li>/</li>
                    <li>Add</li>
                </ul>

            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Add request"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        label="User"
                                        margin="normal"
                                        name="owner"
                                        type="text"
                                        value={email}
                                        variant="outlined"
                                        error={errors.owner}
                                    />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            marginTop: '5px'
                                        }}
                                    >
                                        <Button
                                            onClick={() => setModalStatus({
                                                isOpen: true,
                                                field: 'owner'
                                            })}
                                            color="primary"
                                            variant="contained"
                                        >
                                            Pick the user
                                        </Button>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        disabled
                                        label="Object"
                                        margin="normal"
                                        name="object"
                                        type="text"
                                        value={object}
                                        variant="outlined"
                                        error={errors.object}
                                    />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            marginTop: '5px',
                                        }}
                                    >
                                        <Button
                                            onClick={() => setIsOpenObjectModal(!isOpenObjectModal)}
                                            color="primary"
                                            variant="contained"
                                            disabled={values.user === '' ? true : false}
                                        >
                                            Pick the object
                                        </Button>
                                    </Box>
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="workType">
                                            Type of Work
                                        </InputLabel>
                                        <Select
                                            labelId="workType"
                                            name="workType"
                                            value={values.workType}
                                            label="Type of Work"
                                            onChange={handleChange}
                                        >
                                            {
                                                typeOfWorkList?.map((item) =>
                                                    <MenuItem value={item.value}>
                                                        {
                                                            item.value
                                                        }
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="room">
                                            Place for Work
                                        </InputLabel>
                                        <Select
                                            labelId="room"
                                            name="room"
                                            value={values.room}
                                            label="Place for Work"
                                            onChange={handleChange}
                                        >
                                            {
                                                placeForWorkList?.map((item) =>
                                                    <MenuItem value={item.value}>
                                                        {
                                                            item.value
                                                        }
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        label="Comment"
                                        margin="normal"
                                        name="comment"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.comment}
                                        variant="outlined"
                                        error={errors.comment}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                        <Box style={{
                                            width: '100%',
                                            marginTop: 10,
                                        }}>
                                            <DatePicker
                                                sx={{
                                                    width: '100%',
                                                }}
                                                onError={console.log}
                                                name="date"
                                                variant="static"
                                                inputFormat="DD.MM.YYYY"
                                                label="Date"
                                                renderInput={(props) => <TextField {...props} />}
                                                value={values.date}
                                                onChange={(newValue) => {
                                                    setValues({
                                                        ...values,
                                                        date: newValue
                                                    });
                                                }}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
                <ModalGetUser
                    isOpenModal={modalStatus}
                    setIsOpenModal={setModalStatus}
                    handleChangeOwners={handleChangeOwners}
                />
                <ModalGetObject
                    userEmail={email}
                    isOpenModal={isOpenObjectModal}
                    setIsOpenModal={setIsOpenObjectModal}
                    handleChangeObject={handleChangeObject}
                />
            </Box>
        </>
    );
};

export default RequestsAdd;
