import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'

const UserList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [queryParams, setQueryParams] = useState({
        id: searchParams.get("id") || '',
        email: searchParams.get("email") || '',
        phone: searchParams.get("phone") || '',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.id !== '') {
            params.id = queryParams.id
        }
        if (queryParams.phone !== '') {
            params.phone = queryParams.phone
        }
        if (queryParams.email !== '') {
            params.email = queryParams.email
        }
        console.log(Object.keys(params).length !== 0)
        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }



    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `admin/users?page=${page + 1}&limit=${limit}`;

        if (queryParams.email !== '') {
            endpoint += `&email=${searchParams.get("email")}`;
        }
        if (queryParams.id !== '') {
            endpoint += `&id=${searchParams.get("id")}`;
        }
        if (queryParams.phone !== '') {
                endpoint += `&phone=${searchParams.get("phone")}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.data.totalCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setUsers([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        if (queryParams.id === '') {
            searchParams.delete("id")
            setSearchParams(searchParams);
        }
        if (queryParams.phone === '') {
            searchParams.delete("phone")
            setSearchParams(searchParams);
        }
        if (queryParams.email === '') {
            searchParams.delete("email")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadUsers();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Users
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Box sx={{marginLeft: 2}}>
                                <Link to="/app/user/add">
                                    <Button color="primary" variant="contained">
                                        Add user
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 1400}}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mx: 2,
                                            mb: 1
                                        }}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                margin="normal"
                                                name="email"
                                                onChange={handleChangeQueryParams}
                                                type="text"
                                                value={queryParams.email}
                                                variant="outlined"
                                                style={{width: '100%'}}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Id"
                                                margin="normal"
                                                name="id"
                                                onChange={handleChangeQueryParams}
                                                type="text"
                                                value={queryParams.id}
                                                variant="outlined"
                                                style={{width: '100%', marginLeft: 10}}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Phone number"
                                                margin="normal"
                                                name="phone"
                                                onChange={handleChangeQueryParams}
                                                type="text"
                                                value={queryParams.phone}
                                                variant="outlined"
                                                style={{width: '100%', marginLeft: 10}}
                                            />
                                            <Button
                                                color="warning"
                                                variant="contained"
                                                onClick={handleFilterQueryParams}
                                                sx={{mt: 2, mb: 1}}
                                                style={{minWidth: 80, marginLeft: 10}}
                                            >
                                                Find
                                            </Button>
                                        </Box>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{width: 80}}>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Email
                                                    </TableCell>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Surname
                                                    </TableCell>
                                                    <TableCell>
                                                        Phone number
                                                    </TableCell>
                                                    <TableCell>
                                                        Role
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users?.map((user) => (
                                                    <TableRow hover key={user.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {user?.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {user?.email || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {user?.name || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {user?.surname || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {user?.phone || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {user?.role || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>

                                                                <Box sx={{display: 'flex'}}>
                                                                    <Link to={`/app/user/${user.id}`}>
                                                                        <Button color="primary"
                                                                                variant="contained">
                                                                            Info
                                                                        </Button>
                                                                    </Link>
                                                                    <Box sx={{ml: 2}}>
                                                                        <Link
                                                                            to={`/app/user/edit/${user.id}`}
                                                                            style={user?.role === "admin" ? {pointerEvents: 'none'} : {}}>
                                                                            <Button color="primary"
                                                                                    variant="contained"
                                                                                    disabled={user?.role === "admin"}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </Link>

                                                                    </Box>

                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Number of lines per page:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>

                </Container>
            </Box>
        </>
    );
};

export default UserList;
