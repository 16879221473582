import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'
import Alert from "@material-ui/core/Alert";

const TypeOfWorkList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(true);
    const [placeForWork, setPlaceForWork] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 1400);
    };

    const loadUsers = () => {
        setIsLoaded(true)

        let endpoint = `work/types?page=${page + 1}&limit=${limit}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setPlaceForWork(resp.data.workTypes);
                    setCount(resp.data.totalCount || 0);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setPlaceForWork([]);
                setCount(0);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {
        confirm({
            title: 'Deleting type of work',
            content: 'Are you sure you want to delete this type of work?',
            onConfirm: () => {
                deleteU(`work/types/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', err.response.data.message);
                    });
            }
        });
    };

    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Types of Work</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Types of Work
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type}
                           style={{
                               marginBottom: '10px',
                               display: alert.isVisible ? 'flex' : 'none'
                           }}>
                        {alert.txt}
                    </Alert>
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Box sx={{marginLeft: 2}}>
                                <Link to="/app/type-of-work/add">
                                    <Button color="primary" variant="contained">
                                        Add types of Work
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 800}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{width: 80}}>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Estimate work time
                                                    </TableCell>
                                                    <TableCell>
                                                        Estimate total
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {placeForWork?.map((object) => (
                                                    <TableRow hover key={object.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {object?.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {object?.name || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {object?.duration || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {object?.cost || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                <Box sx={{display: 'flex'}}>
                                                                    <Box sx={{ml: 2}}>
                                                                        <Link to={`/app/type-of-work/edit/${object.id}`}>
                                                                        <Button color="primary"
                                                                                variant="contained"
                                                                                disabled={object?.role === "admin"}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                        </Link>
                                                                    </Box>
                                                                    <Box sx={{ml: 2}}>
                                                                        <Button color="error"
                                                                                variant="contained"
                                                                                onClick={() => onDelete(object.id)}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </Box>

                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Number of lines per page:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default TypeOfWorkList;
