import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    Table, TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import {Link, useSearchParams} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modal from "@mui/material/Modal";
import {useConfirm} from "../Confirm";
import {useDelete, useGet} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";

function ModalGetObject({isOpenModal, setIsOpenModal, handleChangeObject, userEmail}) {

    const getU = useGet();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(true);

    const [objects, setObjects] = useState([]);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [count, setCount] = useState(0);


    const loadUsers = () => {
        setIsModalLoading(true)

        let endpoint = `objects/all?page=${page + 1}&limit=${limit}&email=${userEmail}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setObjects(resp.data.objects);
                    setCount(resp.data.totalCount || 0);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setObjects([]);
                setCount(0);
            })
            .finally(() => {
                setIsModalLoading(false)
            });


    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        loadUsers();
    }, [page, limit, userEmail]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }


    return (
        <Modal
            open={isOpenModal}
            onClose={() => setIsOpenModal(!isOpenModal)}
        >
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setIsOpenModal(!isOpenModal)}
                            >
                                Close
                            </Button>
                        </Box>
                        {isModalLoading
                            ?
                            <div className="loader">
                                <BallTriangle
                                    height="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                />
                            </div>
                            :
                            <Box sx={{pt: 3}}>
                                <Card>
                                    <PerfectScrollbar>
                                        <Box sx={{minWidth: 1400}}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{width: 80}}>
                                                            Id
                                                        </TableCell>
                                                        <TableCell>
                                                            Owner Email
                                                        </TableCell>
                                                        <TableCell>
                                                            Owner phone number
                                                        </TableCell>
                                                        <TableCell>
                                                            Address
                                                        </TableCell>
                                                        <TableCell>
                                                            Type of Object
                                                        </TableCell>
                                                        <TableCell>

                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {objects?.map((object) => (
                                                        <TableRow hover key={object.id}>
                                                            <TableCell sx={{width: 80}}>
                                                                {object?.id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {object?.user.email || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {object?.user.phone || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {object?.address || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {object?.type || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end'
                                                                }}>
                                                                    <Button color="primary"
                                                                            variant="contained"
                                                                            onClick={() => handleChangeObject({
                                                                                id: object.id,
                                                                                address: object?.address
                                                                            })}
                                                                    >
                                                                        Pick
                                                                    </Button>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[]}
                                                            colSpan={7}
                                                            count={count}
                                                            rowsPerPage={limit}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeLimit}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </Box>
                                    </PerfectScrollbar>
                                </Card>
                            </Box>
                        }
                    </>

                </Container>
            </Box>
        </Modal>
    );
}

export default ModalGetObject;
