import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {useGet, usePost, usePut} from "../../API/request";
import React, {useEffect, useState} from "react";
import {BallTriangle} from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/core/Alert";

const TypeOfWorkEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const postU = usePost();
    const getU = useGet();
    const putU = usePut();


    const [values, setValues] = useState({
        name: '',
        cost: '',
        duration: ''

    });
    const [errors, setErrors] = useState({
        name: false,
        cost: false,
        duration: false
    });
    const [isDateUpdated, setIsDateUpdated] = useState({
        name: false,
        cost: false,
        duration: false
    });

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
        setIsDateUpdated({
            ...isDateUpdated,
            [event.target.name]: true
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };


    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name === '') {
            validComplete = false;
            formErrors.name = false;
            showAlert('error', "The Name field should not be empty")
        }

        setErrors(formErrors);
        return validComplete;
    }


    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const data = {

            };

            if (isDateUpdated.duration){
                data.duration = values.duration
            }
            if (isDateUpdated.cost){
                data.cost = values.cost
            }
            if (isDateUpdated.name){
                data.name = values.name
                data.value = values.name
            }

            putU(`work/types/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'You have successfully updated the data');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                })
                .finally(()=>{
                    setSubmitDisabled(false);
                });
        }
    };

    useEffect(() =>{
        setIsLoaded(true)
        getU(`work/types/${id}`)
            .then((response) =>{
                if (response.status === 'success'){
                    setValues({
                        name: response.data.workType.name,
                        value: response.data.workType.value,
                        cost: response.data.workType.cost,
                        duration: response.data.workType.duration
                    })
                }
            })
            .catch((err) =>{
                showAlert('error', err.response.data.message);
            })
            .finally(() =>{
                setIsLoaded(false)
            })
    },[])

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Create Place For Work</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    Place For Work
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Place For Work</li>
                    <li>/</li>
                    <li>Add</li>
                </ul>

            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Add place for work"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Estimate total"
                                        margin="normal"
                                        name="cost"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.cost}
                                        variant="outlined"
                                        error={errors.cost}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Estimate work time (hours and minutes)"
                                        margin="normal"
                                        name="duration"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.duration}
                                        variant="outlined"
                                        error={errors.duration}
                                    />
                                    <Alert severity={alert.type}
                                           style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default TypeOfWorkEdit;
