import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, TextField, Alert, Grid
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import {useGet, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import '../../styles/Avatar/style.css'
import ModalGetUser from "../../components/ModalGetUser/ModalGetUser";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Clear} from "@material-ui/icons";

const ObjectEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [modalStatus, setModalStatus] = useState({
        isOpen: false,
        field: null,
    });
    const [emailsCoOwner, setEmailsCoOwner] = useState([]);
    const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
    const [values, setValues] = useState({
        img: '',
        ObjectType: '',
        square: '',
        address: '',
    });
    const [errors, setErrors] = useState({
        email: false,
        img: false,
        ObjectType: false,
        square: false,
        address: false,
        coOwner: false
    });
    const [isValueEdit, setIsValueEdit] = useState({
        img: false,
        ObjectType: false,
        square: false,
        address: false,
        coOwner: false
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
        setIsValueEdit({
            ...isValueEdit,
            [event.target.name]: true
        });
    };

    const handleChangeOwners = ({email, id}) => {
        setIsValueEdit({
            ...isValueEdit,
            coOwner: true
        });
        setNothingChanged(false);
        if (modalStatus.field === 'coOwner') {
            console.log(email)
            setEmailsCoOwner([
                ...emailsCoOwner,
                email
            ])
            setModalStatus({
                isOpen: false,
                field: null,
            })
        } else {
            setModalStatus({
                isOpen: false,
                field: null,
            })
        }
    }
    const onDeleteSoOwner = (email) => {
        setIsValueEdit({
            ...isValueEdit,
            coOwner: true
        });
        setNothingChanged(false);
        let filter = emailsCoOwner.filter((owner) => owner !== email);
        setEmailsCoOwner(filter)
    }


    const avaUploaded = (event) => {
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            img: event.target.files[0]
        });

        setNothingChanged(false);
        setIsValueEdit({
            ...isValueEdit,
            img: true
        });
    }


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.square > 99999) {
            validComplete = false;
            formErrors.square = false;
            showAlert('error', "Max area of the object: 99999")
        }

        setErrors(formErrors);
        return validComplete;
    };


    const submitInfo = async () => {
        if (nothingChanged) {
            showAlert('error', 'No changes');
            return;
        }

        if (validateInfo()) {
            setSubmitDisabled(true);

            let data = new FormData();

            if (isValueEdit.img){
                data.append('image', values.img);
            }

            if (isValueEdit.square){
                data.append('area', values.square);
            }

            if (isValueEdit.ObjectType){
                data.append('type', values.ObjectType);
            }

            if (isValueEdit.address){
                data.append('address', values.address);
            }

            if (isValueEdit.coOwner){
                data.append('coOwnerEmails', emailsCoOwner);
            }


            putU(`objects/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Data has been successfully updated');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    useEffect(() => {
        setIsLoaded(true)
        getU(`objects/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const data = {
                        square: resp.data.object.area,
                        address: resp.data.object.address,
                        ObjectType: resp.data.object.type,
                    }

                    let coOwners = resp?.data?.object?.coOwners?.map((owner) => owner?.email);

                    setUploadedImg(`${process.env.REACT_APP_API_URL}/uploads/objects/${resp.data.object.image}`)
                    setValues(data)
                    setEmailsCoOwner(coOwners)
                }
            })
            .catch((err) => {
                showAlert('error', err.response.data.message);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit Property</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    Property
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Property</li>
                    <li>/</li>
                    <li>Edit</li>
                </ul>

            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Edit property"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <div className="itemWrapper">
                                        <div className="container">
                                            <input accept="xlsx/*" type="file" style={{display: 'none'}}
                                                   id={1}
                                                   onChange={(event) => avaUploaded(event, 1)}/>
                                            <label htmlFor={1}>
                                                <img src={uploadedImg} className="itemImg"/>
                                                <div className="middle"/>
                                            </label>
                                        </div>
                                        <div className="help-text">
                                            The following extensions are available: .png, .jpg, .gif
                                        </div>
                                    </div>
                                    {
                                        emailsCoOwner?.map((coOwner, index) =>
                                            <TextField
                                                fullWidth
                                                disabled
                                                label={`Co-Owner ${index + 1}`}
                                                margin="normal"
                                                name="owner"
                                                type="text"
                                                value={coOwner}
                                                variant="outlined"
                                                error={errors.owner}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end"
                                                                        sx={{cursor: 'pointer'}}
                                                                        onClick={() => onDeleteSoOwner(coOwner)}
                                                        >
                                                            <Clear/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )
                                    }
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            onClick={() => setModalStatus({
                                                isOpen: true,
                                                field: 'coOwner'
                                            })}
                                            disabled={emailsCoOwner?.length >= 5}
                                            color="primary"
                                            variant="contained"
                                        >
                                            Pick the co-owner of property
                                        </Button>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        label="Property Area (SF)"
                                        margin="normal"
                                        name="square"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.square}
                                        variant="outlined"
                                        error={errors.square}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Address"
                                        margin="normal"
                                        name="address"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.address}
                                        variant="outlined"
                                        error={errors.address}
                                    />
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="ObjectType">
                                            Type of property
                                        </InputLabel>
                                        <Select
                                            labelId="ObjectType"
                                            name="ObjectType"
                                            value={values.ObjectType}
                                            label="Type of property"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={'Apartment'}>
                                                Apartment
                                            </MenuItem>
                                            <MenuItem value={'House'}>
                                                House
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
                <ModalGetUser
                    emailsCoOwner={emailsCoOwner}
                    isOpenModal={modalStatus}
                    setIsOpenModal={setModalStatus}
                    handleChangeOwners={handleChangeOwners}
                />
            </Box>
        </>
    );
};

export default ObjectEdit;
