import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    TextField,
    Alert,
    Grid,
    Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import { useGet, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML
} from 'draft-js';
import '../../styles/Avatar/style.css';

const DocumentEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [content, setContent] = useState('');
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(content))
        )
    );

    const [values, setValues] = useState({
        name: ''
    });
    const [errors, setErrors] = useState({
        name: false
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.key === '') {
            validComplete = false;
            formErrors.key = false;
            showAlert('error', 'Поле Ключ не должно быть пустым');
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submitInfo = async () => {
        if (validateInfo()) {
            setSubmitDisabled(true);

            const data = {
                key: values.key,
                content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            };

            putU(`pages/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Ошибка сервера');
                })
                .finally(() => {
                    setSubmitDisabled(false);
                });
        }
    };

    useEffect(() => {
        setIsLoaded(true);
        getU(`pages/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const data = {
                        key: resp.data.page.key
                    };

                    setEditorState(() =>
                        EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                                convertFromHTML(resp.data.page?.content || '<p></p>')
                            )
                        )
                    );

                    setValues(data);
                }
            })
            .catch(() => {
                showAlert(
                    'error',
                    'Произошла ошибка при загрузке документа, попробуйте перезайти'
                );
            })
            .finally(() => {
                setIsLoaded(false);
            });
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color="grey"
                    ariaLabel="loading"
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Documents</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    Documents
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Documents</li>
                    <li>/</li>
                    <li>Add</li>
                </ul>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
                <Container maxWidth={false}>
                    <Box>
                        <form>
                            <Card>
                                <CardHeader title="Editing a document"/>
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                        fullWidth
                                        label="Key"
                                        margin="normal"
                                        name="key"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.key}
                                        variant="outlined"
                                        error={errors.key}
                                    />
                                    <Typography
                                        style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}
                                    >
                                        Content
                                    </Typography>

                                    <Editor
                                        editorStyle={{
                                            border: '1px solid rgba(0,0,0,0.2)',
                                            minHeight: 500,
                                            padding: 10
                                        }}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                    />
                                </CardContent>
                                <Divider/>
                                <Alert
                                    severity={alert.type}
                                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                                >
                                    {alert.txt}
                                </Alert>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>

                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DocumentEdit;
