import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'
import moment from 'moment'
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const RequestsList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const statusesList = [
        'All',
        'New',
        'Accept',
        'In progress',
        'Completed',
        'Canceled'
    ]

    const [queryParams, setQueryParams] = useState({
        email: searchParams.get("email") || '',
        status: searchParams.get("status") || 'All',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}

        if (queryParams.status !== '') {
            params.status = queryParams.status
        }

        if (queryParams.email !== '') {
            params.email = queryParams.email
        }

        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `requests/all?page=${page + 1}&limit=${limit}`;

        if (queryParams.email !== '') {
            endpoint += `&email=${searchParams.get("email")}`;
        }

        if (queryParams.status !== '') {
            endpoint += `&orderBy=${searchParams.get("status")}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setRequests(resp.data.requests);
                    setCount(resp.data.totalCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setRequests([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        if (queryParams.email === '') {
            searchParams.delete("email")
            setSearchParams(searchParams);
        }
        if (queryParams.status === '') {
            searchParams.delete("status")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadUsers();
    }, [page, limit, searchParams]);

    useEffect(() =>{
        const params = {}
        if (queryParams.status !== '') {
            params.status = queryParams.status
        }
        if (queryParams.email !== '') {
            params.email = queryParams.email
        }
        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    },[])



    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Requests</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Requests
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                        <Link to="/app/requests/add">
                                        <Button color="primary" variant="contained">
                                            Add request
                                        </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1225}}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <FormControl
                                                        fullWidth sx={{mt: 2}}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <InputLabel id="status">
                                                            Status
                                                        </InputLabel>
                                                        <Select
                                                            labelId="status"
                                                            name="status"
                                                            value={queryParams.status}
                                                            label="Status"
                                                            onChange={handleChangeQueryParams}
                                                        >
                                                            {
                                                                statusesList?.map((item) => <MenuItem
                                                                    value={item}>{item}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="User email"
                                                        margin="normal"
                                                        name="email"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.email}
                                                        variant="outlined"
                                                        style={{width: '100%'}}
                                                    />
                                                    <Button
                                                        color="warning"
                                                        variant="contained"
                                                        onClick={handleFilterQueryParams}
                                                        sx={{mt: 2, mb: 1}}
                                                        style={{minWidth: 80, marginLeft: 10}}
                                                    >
                                                        Find
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Date
                                                            </TableCell>
                                                            <TableCell>
                                                                Owner
                                                            </TableCell>
                                                            <TableCell>
                                                                Status
                                                            </TableCell>
                                                            <TableCell>
                                                                Property
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {requests?.map((request) => (
                                                            <TableRow hover key={request.id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {request?.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {moment(request?.date).format('DD.MM.yyyy') || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {request?.user.email || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {request?.status || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {request?.object?.address || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{
                                                                        ml: 2,
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end'
                                                                    }}>
                                                                        <Link to={`/app/requests/${request.id}`}>
                                                                            <Button color="primary"
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        marginRight: '10px'
                                                                                    }}
                                                                            >
                                                                                Info
                                                                            </Button>
                                                                        </Link>
                                                                        <Link to={`/app/requests/edit/${request.id}`}>
                                                                            <Button color="primary" variant="contained">
                                                                                Change status
                                                                            </Button>
                                                                        </Link>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Number of lines per page:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default RequestsList;
