import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Alert,
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid, Input, TextField, Typography
} from '@material-ui/core';
import AccountProfile from '../../components/account/AccountProfile';
import AccountProfileDetails from '../../components/account/AccountProfileDetails';
import {useDispatch} from "react-redux";
import {useGet, usePut} from "../../API/request";
import {setUser} from "../../redux/slices/auth";

const Account = () => {

    const dispatch = useDispatch();
    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        name: '',
        surname: '',
        number: '',
        email: '',
        password: '',
        confirm: ''
    });
    const [errors, setErrors] = useState({
        name: false,
        surname: false,
        number: false,
        email: false,
        password: false,
        confirm: false
    });
    const [isUpdatedValue, setIsUpdatedValue] = useState({
        name: false,
        surname: false,
        number: false,
        email: false,
        password: false,
        confirm: false
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alertInfo, setAlertInfo] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const [alertPassword, setAlertPassword] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });


    const loadUserData = () => {
        getU(`users/current`)
            .then((resp) => {
                if (resp.status === 'success') {
                    console.log(resp.data.user)
                    let userData = resp.data.user;
                    dispatch(setUser(resp.data.user))

                    let vals = {
                        name: userData.name || '',
                        surname: userData.surname || '',
                        number: userData.phone || '',
                        email: userData.email || '',
                    };
                    setValues(vals);
                }
            })
            .catch((err) => {
                showAlertInfo('error', 'Данные не были загружены');
            });
    }


    const handleChange = (event) => {
        if (nothingChanged === true) {
            setNothingChanged(false);
        }

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
        setIsUpdatedValue({
            ...isUpdatedValue,
            [event.target.name]: true
        })
    };

    const showAlertInfo = (type, text) => {
        setAlertInfo({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlertInfo({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const showAlertPassword = (type, text) => {
        setAlertPassword({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlertPassword({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name.trim() === '') {
            validComplete = false;
            formErrors.firstName = true;
            showAlertInfo('error', "The Name field should not be empty")

        }
        if (values.surname.trim() === '') {
            validComplete = false;
            formErrors.surname = true;
            showAlertInfo('error', "The Surname field should not be empty")
        }
        if (values.number.trim() === '') {
            validComplete = false;
            formErrors.number = true;
            showAlertInfo('error', "The Phone number field should not be empty")
        }

        if (values.email.trim() === '') {
            validComplete = false;
            formErrors.password = true;
            showAlertInfo('error', "The Email field should not be empty")
        }

        setErrors(formErrors);
        return validComplete;
    };

    const validatePassword = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.password) {
            if (!values.password) {
                validComplete = false;
                formErrors.password = true;
                showAlertPassword('error', "The Password field should not be empty")
            } else if (values.password.length < 8) {
                validComplete = false;
                formErrors.password = true;
                showAlertPassword('error', "The password must contain more than 8 characters")
            }

            if (values.password !== values.confirm) {
                validComplete = false;
                formErrors.confirm = true;
                showAlertPassword('error', "Passwords don't match")
            }
        }

        setErrors(formErrors);
        return validComplete;
    };

    const onSubmitInfo = async () => {
        setSubmitDisabled(true);

        if (nothingChanged) {
            showAlertInfo('error', 'No changes');
            return;
        }

        if (validateInfo()) {

            const payload = {

            };

            if (isUpdatedValue.name){
                payload.name = values.name
            }
            if (isUpdatedValue.surname){
                payload.surname = values.surname
            }
            if (isUpdatedValue.number){
                payload.phone = values.number
            }

            putU(`users/current`, payload)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlertInfo('success', 'You have successfully updated the data');
                        loadUserData();
                    }
                })
                .catch((err) => {
                    showAlertInfo('error', err.response.data.message);
                });
        }
    };

    const onSubmitPassword = async () => {
        setSubmitDisabled(true);

        if (nothingChanged) {
            showAlertInfo('error', 'Нет изменений');
            return;
        }

        if (validatePassword()) {
            const payload = {
                password: values.password,
            };

            putU(`admin/current/password`, payload)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlertPassword('success', 'You have successfully updated your password');
                        setValues({...values, password: '', confirm: ''})
                    }
                })
                .catch((err) => {
                    showAlertPassword('error', err.response.data.message);
                });
        }
    };

    useEffect(() => {
        loadUserData()
    }, []);


    return (
        <>
            <Helmet>
                <title>Account</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Account
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth="lg">

                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Card>
                            <Divider/>
                            <CardContent>
                                <Grid container spacing={3} matginTop={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name="name"
                                            onChange={handleChange}
                                            required
                                            value={values.name}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Surname"
                                            name="surname"
                                            onChange={handleChange}
                                            required
                                            value={values.surname}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Phone number"
                                            name="number"
                                            onChange={handleChange}
                                            required
                                            value={values.number}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Alert severity={alertInfo.type}
                                       style={{display: alertInfo.isVisible ? 'flex' : 'none', margin: '12px 0'}}>
                                    {alertInfo.txt}
                                </Alert>
                            </CardContent>
                            <Divider/>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={submitDisabled}
                                    onClick={onSubmitInfo}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Card>

                        <Card sx={{mt: 4}}>
                            <Divider/>
                            <CardContent>
                                <Grid container spacing={3} matginTop={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            name="password"
                                            onChange={handleChange}
                                            required
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                            error={errors.password}
                                            helperText={errors.password && 'Password shouldn\'t be shorter than 6 characters'}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Confirm"
                                            name="confirm"
                                            onChange={handleChange}
                                            type="password"
                                            required
                                            value={values.confirm}
                                            variant="outlined"
                                            error={errors.confirm}
                                            helperText={errors.confirm && 'Passwords are different'}
                                        />
                                    </Grid>

                                </Grid>

                                <Alert severity={alertPassword.type}
                                       style={{display: alertPassword.isVisible ? 'flex' : 'none', margin: '12px 0'}}>
                                    {alertPassword.txt}
                                </Alert>
                            </CardContent>
                            <Divider/>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={submitDisabled}
                                    onClick={onSubmitPassword}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Card>
                    </form>

                </Container>
            </Box>
        </>
    );

};

export default Account;
