import {Helmet} from 'react-helmet';
import {
    Box,
    Button, Card,
    Container, Divider,
    Grid, Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, TextField
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useGet} from '../../API/request';
import {BallTriangle} from 'react-loader-spinner';
import StatisticsItem from "../../components/dashboard/StatisticsItem";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {ArrowRight} from '@mui/icons-material';
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

const Dashboard = () => {

    const [isLoaded, setIsLoaded] = useState(true);

    const getU = useGet();

    const [data, setData] = useState({
        date: 0,
    });

    const [requests, setRequests] = useState([]);

    useEffect(() => {
        getU(`requests/all?page=1&limit=5&orderBy=New`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setRequests(resp.data.requests);
                }
            })
            .catch((err) => {
                setRequests([]);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>

            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Dashboard
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem name={data?.date}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem name={data?.date}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem name={data?.date}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem name={data?.date}/>
                        </Grid>
                    </Grid>
                    <Container
                        style={{
                            marginTop: "20px",
                            padding: 0,
                        }}
                        maxWidth={true}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 1000}}>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        New requests
                                    </Typography>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: 80}}>
                                                    Id
                                                </TableCell>
                                                <TableCell>
                                                    Date
                                                </TableCell>
                                                <TableCell>
                                                    Owner
                                                </TableCell>
                                                <TableCell>
                                                    Status
                                                </TableCell>
                                                <TableCell>
                                                    Object
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {requests?.map((request) => (
                                                <TableRow hover key={request.id}>
                                                    <TableCell sx={{width: 80}}>
                                                        {request?.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(request?.date).format('DD.MM.yyyy') || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {request?.user.email || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {request?.status || '---'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {request?.object?.address || '---'}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </Box>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    height: 40
                                }}>
                                    <Link to="/app/requests">
                                        <Button
                                            sx={{width: 130}}
                                            endIcon={<ArrowRight/>}
                                        >
                                            View all
                                        </Button>
                                    </Link>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Container>
                </Container>
            </Box>
        </>
    );
};


export default Dashboard;
