import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    Table, TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import {Link, useSearchParams} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modal from "@mui/material/Modal";
import {useConfirm} from "../Confirm";
import {useDelete, useGet} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";

function ModalGetUser({isOpenModal, setIsOpenModal, handleChangeOwners, emailsCoOwner}) {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(true);

    const [users, setUsers] = useState([]);
    const [filterEmail, setFilterEmail] = useState('');
    const [filterId, setFilterId] = useState('');
    const [filterPhone, setFilterPhone] = useState('');


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [count, setCount] = useState(0);


    const loadUsers = () => {
        setIsModalLoading(true)

        let endpoint = `admin/users?page=${page + 1}&limit=${limit}`;

        if (filterEmail !== '') {
            endpoint += `&email=${filterEmail}`;
        }
        if (filterId !== '') {
            endpoint += `&id=${filterId}`;
        }
        if (filterPhone !== '') {
            endpoint += `&phone=${filterPhone}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.data.totalCount || 0);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setUsers([]);
                setCount(0);
            })
            .finally(() => {
                setIsModalLoading(false)
            });


    };

    const loadUsersWithArgParams = (searchParams) => {
        setIsModalLoading(true)

        let endpoint = `admin/users?page=${page + 1}&limit=${limit}`;

        if (searchParams.email !== '') {
            endpoint += `&email=${searchParams.email}`;
        }

        if (searchParams.id !== '') {
            endpoint += `&id=${searchParams.id}`;
        }

        if (searchParams.phone !== '') {
            endpoint += `&phone=${searchParams.phone}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.data.totalCount || 0);
                }
            })
            .catch(() => {
                setUsers([]);
                setCount(0);
            })
            .finally(() => {
                setIsModalLoading(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterEmail = (event) => {
        setFilterEmail(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: '',
                id: filterId,
                phone: filterPhone,
            });
        }
    };

    const handleFilterId = (event) => {
        setFilterId(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: filterEmail,
                id: '',
                phone: filterPhone,
            });
        }
    };

    const handleFilterPhone = (event) => {
        setFilterPhone(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: filterEmail,
                id: filterId,
                phone: '',
            });
        }
    };


    const handleFilter = () => {
        loadUsers();
    };

    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }


    return (
        <Modal
            open={isOpenModal.isOpen}
            onClose={() => setIsOpenModal({
                isOpen: false,
                field: null
            })}
        >
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>

                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setIsOpenModal({
                                    isOpen: false,
                                    field: null
                                })}
                            >
                                Close
                            </Button>
                        </Box>
                        {isModalLoading
                            ?
                            <div className="loader">
                                <BallTriangle
                                    height="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                />
                            </div>
                            :
                            <Box sx={{pt: 3}}>
                                <Card>
                                    <PerfectScrollbar>
                                        <Box sx={{minWidth: 1400}}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                mx: 2,
                                                mb: 1
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Email"
                                                    margin="normal"
                                                    name="filterEmail"
                                                    onChange={handleFilterEmail}
                                                    type="text"
                                                    value={filterEmail}
                                                    variant="outlined"
                                                    style={{width: '100%'}}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Id"
                                                    margin="normal"
                                                    name="filterId"
                                                    onChange={handleFilterId}
                                                    type="text"
                                                    value={filterId}
                                                    variant="outlined"
                                                    style={{width: '100%', marginLeft: 10}}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Phone number"
                                                    margin="normal"
                                                    name="phone"
                                                    onChange={handleFilterPhone}
                                                    type="text"
                                                    value={filterPhone}
                                                    variant="outlined"
                                                    style={{width: '100%', marginLeft: 10}}
                                                />
                                                <Button
                                                    color="warning"
                                                    variant="contained"
                                                    onClick={handleFilter}
                                                    sx={{mt: 2, mb: 1}}
                                                    style={{minWidth: 80, marginLeft: 10}}
                                                >
                                                    Find
                                                </Button>
                                            </Box>
                                            <Divider/>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{width: 80}}>
                                                            Id
                                                        </TableCell>
                                                        <TableCell>
                                                            Email
                                                        </TableCell>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            Surname
                                                        </TableCell>
                                                        <TableCell>
                                                            Phone number
                                                        </TableCell>
                                                        <TableCell>
                                                            Role
                                                        </TableCell>
                                                        <TableCell>

                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {users?.map((user) => (
                                                        <TableRow hover key={user.id}>
                                                            <TableCell sx={{width: 80}}>
                                                                {user?.id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {user?.email || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {user?.name || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {user?.surname || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {user?.phone || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {user?.role || '---'}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end'
                                                                }}>
                                                                    <Button color="primary"
                                                                            variant="contained"
                                                                            onClick={() => {
                                                                                setIsOpenModal({
                                                                                    field: null,
                                                                                    isOpen: false,
                                                                                })
                                                                                handleChangeOwners({
                                                                                    email: user.email,
                                                                                    id: user.id
                                                                                })
                                                                            }}
                                                                            disabled={
                                                                                emailsCoOwner?.includes(user?.email) && isOpenModal?.field === 'coOwner'
                                                                            }
                                                                    >
                                                                        Pick
                                                                    </Button>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[]}
                                                            colSpan={7}
                                                            count={count}
                                                            rowsPerPage={limit}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeLimit}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </Box>
                                    </PerfectScrollbar>
                                </Card>
                            </Box>
                        }
                    </>

                </Container>
            </Box>
        </Modal>
    );
}

export default ModalGetUser;
