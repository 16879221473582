import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';

const DocumentsList = () => {
    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const loadPages = () => {
        setIsDataLoading(true);
        setIsLoaded(true);

        let endpoint = `pages/?page=${page + 1}&limit=${limit}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setPages(resp.data.pages);
                    setCount(resp.data.currentCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response);
                setPages([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const onDelete = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить документ?',
            onConfirm: () => {
                deleteU(`pages/${id}`).then((resp) => {
                    if (resp.status === 'success') {
                        loadPages();
                    }
                });
            }
        });
    };

    useEffect(() => {
        loadPages();
    }, [page, limit]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color="grey"
                    ariaLabel="loading"
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Documents</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">Documents</Box>
            </Box>
            <Box
                sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
            >
                <Container maxWidth={false}>
                    {isDataLoading ? (
                        <UserListSkelet/>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Box sx={{ marginLeft: 2 }}>
                                    <Link to="/app/documents/add">
                                        <Button color="primary" variant="contained">
                                            Add Document
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                            <Box sx={{ pt: 3 }}>
                                <Card>
                                    <PerfectScrollbar>
                                        <Box sx={{ minWidth: 400 }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                                                        <TableCell>Key</TableCell>
                                                        <TableCell>Content</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {pages?.map((page) => (
                                                        <TableRow hover key={page.id}>
                                                            <TableCell sx={{ width: 80 }}>
                                                                {page?.id}
                                                            </TableCell>
                                                            <TableCell>{page?.key || '---'}</TableCell>
                                                            <TableCell>
                                                                <Box
                                                                    sx={{
                                                                        maxWidth: 200,
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                        maxHeight: 60
                                                                    }}
                                                                >
                                                                    {page?.content || '---'}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{ display: 'flex' }}>
                                                                    <Box sx={{ display: 'flex' }}>
                                                                        <Box sx={{ ml: 2 }}>
                                                                            <Link
                                                                                to={`/app/documents/edit/${page.id}`}
                                                                            >
                                                                                <Button
                                                                                    color="primary"
                                                                                    variant="contained"
                                                                                >
                                                                                    Edit
                                                                                </Button>
                                                                            </Link>
                                                                        </Box>
                                                                        <Box sx={{ ml: 2 }}>
                                                                            <Button
                                                                                color="error"
                                                                                variant="contained"
                                                                                onClick={() => onDelete(page.id)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            labelRowsPerPage={
                                                                <span>Number of lines per page:</span>
                                                            }
                                                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                            colSpan={7}
                                                            count={count}
                                                            rowsPerPage={limit}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeLimit}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </Box>
                                    </PerfectScrollbar>
                                </Card>
                            </Box>
                        </>
                    )}
                </Container>
            </Box>
        </>
    );
};

export default DocumentsList;
